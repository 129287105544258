/**
 * navigation
 * by ole fjarestad
 */


$(document).ready(function($) {

	var $this = $( this );
	 

	// add icon to list elements with submenus
	// $( '.siteNavigation li.menu-item-has-children > a' ).after( '<span class="siteNavigation__openSubmenu">+</span>' );
	$( '.siteNavigation li.menu-item-has-children > a' ).append( '<span class="siteNavigation__openSubmenu">+</span>' );


	// show submenus when clicking the icon added above
	$this.on('click', function(event) {
		if ( $(event.target).closest('.siteNavigation').length == 0 ) {
			$( '.siteNavigation li.menu-item-has-children > a' ).removeClass( 'subMenuVisible' );
			$( '.sub-menu' ).removeClass( 'subMenuVisible' );
		}
	});


	// show submenus when clicking the icon added above
	$this.on('click', '.siteNavigation li.menu-item-has-children > a', function(event) {
		var $this = $( this );
		$this.toggleClass( 'subMenuVisible' );
		$this.closest( 'li.menu-item-has-children' ).find( '> .sub-menu' ).toggleClass( 'subMenuVisible' );
		event.preventDefault();
	});


	// show mobile menu when clicking the "hamburger" icon
	$this.on('click', '#js-siteNavigationControl--open', function(event) {
		$( 'html' ).addClass('navOpen');
		$( this ).attr( 'aria-expanded', 'true' );
		$( '#js-siteNavigationControl--open' ).attr( 'aria-expanded', 'true' );
		event.preventDefault();
	});


	// hide mobile menu when clicking the "x" icon
	$this.on('click', '#js-siteNavigationControl--close', function(event) {
		$( 'html' ).removeClass('navOpen');
		$( this ).attr( 'aria-expanded', 'false' );
		$( '#js-siteNavigationControl--open' ).attr( 'aria-expanded', 'false' );
		event.preventDefault();
	});


	// hide mobile menu when clicking html overlay
	$this.on('click', '.swOverlay', function(event) {
		$( 'html' ).removeClass('navOpen');
		$( '#js-siteNavigationControl--open' ).attr( 'aria-expanded', 'false' );
		$( '#js-siteNavigationControl--close' ).attr( 'aria-expanded', 'false' );
	});

});