jQuery(document).ready(function($) {
	setTimeout(function(){
		console.log('resized iframe');
		iframeSizing();
	}, 100)
});

$( window ).resize(function(event) {
	iframeSizing();
});


/**
 * resize all iframes so that their height equal their (width/16)*9 (aka make them 16:9 format)
 * force all iframes to width: 100%
 * assumes all iframes, except the one from checkfront (booking), are videos in 16:9 format
 */
function iframeSizing () {

	$( 'iframe:not(.CHECKFRONT_DROPLET)' ).each(function(index, el) {

		$el = $( el );
		$el.width( '100%' );
		$el.height( $el.width() / 16 * 9 );

	});

}