/**
 * helpers
 * by ole fjarestad
 */

$(document).ready(function($) {

	// get media query breakpoints (from before element of html)
	var sw_breakpoint = {};
	sw_breakpoint.refreshValue = function () {
	    this.value = window.getComputedStyle(document.querySelector('html'), ':before').getPropertyValue('content').replace(/\"/g, '');
	};
	// add helper classes to html depending on viewport width
	function add_size_class() {
		if ( sw_breakpoint.value == 'small' ) {
			$( 'html' ).addClass( 'swSmall' );
			$( 'html' ).removeClass( 'swMedium' );
			$( 'html' ).removeClass( 'swLarge' );
		}
		else if ( sw_breakpoint.value == 'medium' ) {
			$( 'html' ).addClass( 'swMedium' );
			$( 'html' ).removeClass( 'swSmall' );
			$( 'html' ).removeClass( 'swLarge' );
		}
		else if ( sw_breakpoint.value == 'large' ) {
			$( 'html' ).addClass( 'swLarge' );
			$( 'html' ).removeClass( 'swSmall' );
			$( 'html' ).removeClass( 'swMedium' );
		}
	}
	$(window).resize(function () {    
		sw_breakpoint.refreshValue();
		add_size_class();
	}).resize();
	
	
	// add user agent string to html element. lets us target specific browsers in our css
	var doc = document.documentElement;
	doc.setAttribute('data-useragent', navigator.userAgent);

});