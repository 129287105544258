$(document).ready(function(){
    $('a[href*="mailto:"]').on('click', function (e)
    {
        if ( typeof gtag ==  'function' ){
            gtag('event', 'click',
                {
                    'event_category': 'generate_lead',
                    'event_label' : 'epostadresse',
                }
            );
        }
        if ( typeof fbq ==  'function' ){
            fbq('track', 'Lead', {content_name: 'Mail'});
        }
    });


    $('a[href*="tel:"]').on('click', function (e)
    {
        if ( typeof gtag ==  'function' ){
            gtag('event', 'click',
                {
                    'event_category': 'generate_lead',
                    'event_label' : 'tlfnummer',
                }
            );
        }
    });


    $(document).on('click', 'a.swBtn', function (e)
    {
        if ( typeof gtag ==  'function' ){
            gtag('event', 'click',
                {
                    'event_category': 'generate_lead',
                    'event_label' : 'button click',
                }
            );
        }
    });

    $(document).on('submit', '#js--contactForm', function (e)
    {
        if ( typeof fbq ==  'function' ){
            fbq('track', 'Lead', {content_name: 'Contact form'});
        }
    });


});