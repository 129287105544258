$( document ).on('mouseenter', '#js--mapOverlay .js--triggerMapPopup', function(e) {
	var targetPopup = $( this ).data( 'open' );
	showPopup( targetPopup );
});

$( document ).on('mouseleave', '#js--mapOverlay .js--triggerMapPopup', function(e) {
	hidePopups();
});




/**
 * show popup
 * @param string popup. popup selector. required
 * by ole fjarestad
 */
function showPopup ( popup )
{

	if ( typeof popup == 'undefined' ) {
		return;
	}

	// original popup must be visible in order for new (duplicate) popup to be visible
	$popup = $( popup );
	$popup.addClass( 'block' );


}


/**
 * hide all popups
 * by ole fjarestad
 */
function hidePopups ()
{

	// hide all (original) popups
	$( '.mapPopup' ).removeClass( 'block' );

}