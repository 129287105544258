// import 'imports?jQuery=jquery!owl.carousel';
import 'owl.carousel';

jQuery(document).ready(function($) {
	
	var carouselSettings = {
		items: 1,
		loop: true,
		dots: false,
		nav: true,
		navText: [ '<span class="swIcon swArrowleft swDark swMedium"></span>', '<span class="swIcon swArrowright swDark swMedium"></span>' ],
	}

	$( '.owl-carousel' ).owlCarousel( carouselSettings );

});